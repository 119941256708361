import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Separator } from "../../components/ui/separator";
import { useNavigate } from 'react-router-dom';

const EditionArea = (props) => {

    const navigate = useNavigate();

    return (
        <div className="w-full max-w-7xl mx-auto p-4 bg-white min-h-screen  text-black">
            <div className="text-left mb-4">
                <span className="text-sm text-gray-600 hover:text-gray-800 cursor-pointer"
                    onClick={() => navigate(`/scenario/${props.edition.newspaper.scenario._id}`)} >
                    ← Return to {props.edition.newspaper.scenario.name}
                </span>
            </div>
            {/* Newspaper Header */}
            <div className="text-center mb-6 border-b-2 border-black pb-4">
                <h1 className="text-4xl font-bold font-serif mb-2">{props.edition.newspaper.name}</h1>
                <div className="text-xl mb-2">
                    {new Date(props.edition.date).toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}
                </div>
                <p className="text-gray-600 italic">{props.edition.newspaper.description}</p>
            </div>

            <div className="flex gap-6">
                {/* Sidebar with edition links */}
                <div className="w-64 flex-shrink-0">
                    <Card>
                        <CardHeader>
                            <CardTitle className="text-lg">Editions</CardTitle>
                        </CardHeader>
                        <CardContent className="p-0">
                            <ScrollArea className="h-[600px]">
                                {props.edition.newspaper.editions.map((edition) => {
                                    const formattedDate = new Date(edition.date).toLocaleDateString('en-US', {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    });
                                    const isActive = edition.date === props.edition.date;

                                    return (
                                        <button
                                            key={edition.date}
                                            onClick={() => navigate(`/edition/${edition._id}`)}
                                            className={`block w-full text-left px-4 py-2 hover:bg-gray-100 ${isActive ? 'bg-gray-100 font-semibold' : ''
                                                }`}
                                        >
                                            {formattedDate}
                                        </button>
                                    );
                                })}
                            </ScrollArea>
                        </CardContent>
                    </Card>
                </div>

                {/* Main content area */}
                <div className="flex-1">
                    <ScrollArea className="h-[800px]">
                        <div className="columns-2 gap-6 [&>*:first-child]:mt-0">
                            {props.edition.articles.map((article, index) => (
                                <article
                                    key={index}
                                    className={`break-inside-avoid-column mb-6 ${index === 0 ? 'column-span-all mb-6' : ''
                                        }`}
                                >
                                    <h2 className="text-2xl font-bold font-serif mb-4">{article.headline}</h2>
                                    <Separator className="mb-4" />
                                    {article.body.split('\n').map((paragraph, pIndex) => (
                                        <p key={pIndex} className="mb-4 text-justify leading-relaxed">
                                            {paragraph}
                                        </p>
                                    ))}
                                </article>
                            ))}
                        </div>
                    </ScrollArea>
                </div>
            </div>
        </div>
    );
};

export default EditionArea;