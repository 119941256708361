import { gql } from '@apollo/client'

export const GET_EDITION = gql`
    query Edition($editionId: ID!) {
        edition(id: $editionId)
        {
            edition
            {
                _id
                date
                articles
                {
                    headline
                    body
                }
                newspaperId
                newspaper
                {
                    _id
                    name
                    description
                    editions
                    {
                        _id
                        date
                    }
                    scenario
                    {
                        _id
                        name
                    }
                }
            }
        }
    }`

