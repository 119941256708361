import React from 'react';
import { Plus } from 'lucide-react';
import { useState } from 'react';
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";
import { useToast } from "../components/ui/use-toast";
import { useMutation } from "@apollo/client";
import { CREATE_SCENARIO_REQUEST } from '../Queries/CreateScenarioRequest';
import { useAuth0 } from '@auth0/auth0-react';

const CreateScenarioForm = () => {
    const [scenarioText, setScenarioText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { toast } = useToast();
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const [createScenarioRequest] = useMutation(CREATE_SCENARIO_REQUEST);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!scenarioText.trim()) return;

        // If user is not authenticated, redirect to Auth0 login
        if (!isAuthenticated) {
            loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname,
                    scenarioText: scenarioText // Optionally save the text they entered
                }
            });
            return;
        }

        setIsLoading(true);

        try {
            console.log('Creating scenario:', scenarioText);

            // Assuming createScenarioRequest is passed as a prop or available in context
            const response = await createScenarioRequest({
                variables: { scenarioDescription: scenarioText.trim() }
            });

            toast({
                title: "Success!",
                description: "Your village has been created.",
                variant: "success",
            });

            setScenarioText('');
        } catch (error) {
            console.error('Error creating village:', error);

            toast({
                title: "Error",
                description: "Failed to create village. Please try again.",
                variant: "destructive",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4 text-xl">
            <Textarea
                value={scenarioText}
                onChange={(e) => setScenarioText(e.target.value)}
                placeholder="Enter your village description..."
                className="h-24 resize-none text-2xl"
            />
            <div className="flex justify-center">
                <Button
                    type="submit"
                    disabled={!scenarioText.trim() || isLoading}
                    className="flex items-center gap-2 text-4xl px-24 py-12">
                    {isLoading ? (
                        <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent" />
                    ) : (
                        <Plus className="w-8 h-8" />
                    )}
                    {isLoading ? 'Creating...' : (isAuthenticated ? 'Create!' : 'Login to Create')}
                </Button>
            </div>
        </form>
    );
};

export default CreateScenarioForm;