import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NewspaperButton = (props) => {

    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex' }}>
            <Button style={{ flex: '1' }}
                className="btn btn-secondary btn-lg"
                onClick={() => navigate(`/newspaper/${props.newspaper._id}`)}>
                <p>{props.newspaper.name}</p>
                <p>{props.newspaper.description}</p>
            </Button>
        </div >
    );
}

export default NewspaperButton;