import { useQuery } from '@apollo/client'
import React from 'react';
import ReactLoading from 'react-loading';
import { GET_EDITION } from '../../Queries/GetEdition';
import { useParams } from 'react-router-dom';
import EditionArea from './EditionArea';

const Edition = (props) => {

    const { id } = useParams();

    const { loading, error, data } = useQuery(GET_EDITION, {
        variables: { editionId: id }
    });

    if (loading) {
        return (
            <ReactLoading type="spinningBubbles" color="#444" />
        );
    }
    else if (error) {
        return (
            <p>Something went wrong...</p>
        );
    }

    return (
        <EditionArea
            edition={data.edition.edition} />
    );
};

export default Edition;