import React from 'react';
import { Plus } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import CreateScenarioForm from './CreateScenarioForm';

const CreateScenarioCard = () => {

    return (
        <Card>
            <CardHeader>
                <CardTitle className="flex items-center gap-2">
                    <Plus className="w-5 h-5 text-blue-500" />
                    Create A New Scenario
                </CardTitle>
            </CardHeader>
            <CardContent>
                <CreateScenarioForm />
            </CardContent>
        </Card>
    );
};

export default CreateScenarioCard;