import { gql, useQuery, useMutation } from '@apollo/client'
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import OverworldBackground from '../Simulation/Overworld/OverworldBackground';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const GET_OVERWORLD = gql`
    query Scenario($scenarioId: ID!) {
        scenario(id: $scenarioId) {
            success
            scenario {
                imageFilename
                name
                description
                currentDateTime
                outsideAgents {
                    _id
                    agentDescription {
                        resizedIconFilename
                        portraitFilename
                    }
                    name
                    status
                    emoji
                    description
                }
                locations {
                    _id
                    resizedImageFilename
                    name
                    allAgents {
                        _id
                        agentDescription {
                            resizedChibiFilename
                        }
                        name
                        status
                        emoji
                    }
                }
                newspapers {
                    _id
                    name
                    description
                }
            }
        }
    }
`;

const UPDATE_SCENARIO_REQUEST = gql`
    mutation UpdateScenarioRequest($scenarioId: ID!) {
        updateRequest(scenarioId: $scenarioId) {
            success
            errors
            response
        }
    }
`;

const Overworld = () => {
    const { id } = useParams();
    const { isAuthenticated } = useAuth0();

    // Set up the mutation
    const [updateScenario, { error: mutationError }] = useMutation(UPDATE_SCENARIO_REQUEST);

    const { loading, error: queryError, data } = useQuery(GET_OVERWORLD, {
        variables: { scenarioId: id },
        pollInterval: isAuthenticated ? 5 * 60 * 1000 : 0, // 5 minutes in milliseconds, Only poll if authenticated
        fetchPolicy: 'network-only' // Always fetch fresh data
    });

    // Effect to run the mutation every 5 minutes if authenticated
    useEffect(() => {
        if (!isAuthenticated) return; // Skip if not authenticated

        const executeMutation = async () => {
            try {
                await updateScenario({
                    variables: { scenarioId: id }
                });
            } catch (err) {
                console.error('Mutation error:', err);
            }
        };

        // Execute immediately
        executeMutation();

        // Set up interval
        const interval = setInterval(executeMutation, 5 * 60 * 1000);

        // Cleanup on unmount
        return () => clearInterval(interval);
    }, [id, updateScenario, isAuthenticated]);

    // Handle loading state
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <ReactLoading type="spinningBubbles" color="#444" />
            </div>
        );
    }

    // // Handle errors (both query and mutation)
    // if (queryError || mutationError) {
    //     return (
    //         <div className="p-4 text-red-600">
    //             Error: {queryError?.message || mutationError?.message}
    //         </div>
    //     );
    // }

    // Handle missing data
    if (!data?.scenario?.scenario) {
        return (
            <div className="p-4">
                No scenario data available
            </div>
        );
    }

    return <OverworldBackground scenario={data.scenario.scenario} />;
};

export default Overworld;